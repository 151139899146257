/* Add left and right padding to element */

.wrapper {
  --min-inline-padding: calc(var(--space-xs) * 1);
  --ideal-inline-padding: 15%;
  --max-inline-padding: calc(var(--space-xxl) * 5);

  padding-inline: clamp(
    var(--min-inline-padding),
    var(--ideal-inline-padding),
    var(--max-inline-padding)
  );
}
