.grid {
  display: grid;
  gap: var(--gap, 1rem);
}

.flex {
  display: flex;
  gap: var(--gap, 1rem);
}

.flex-col {
  flex-direction: column;
}

.flow > *:where(:not(:first-child)) {
  margin-top: var(--flow-space, 1rem);
}
