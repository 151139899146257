@use 'src/scss/abstracts/_breakpoints.scss' as *;

.partners-container {
  margin: 60px 0;
  @include respond-to('large') {
    margin-top: 131px;
  }
}

.partners-container h2 {
  text-align: center;
  font-family: var(--ff-headings);
  font-weight: bold;
  margin-bottom: 30px;
}

.partners-grid {
  display: grid;

  @include respond-to('large') {
    grid-template-columns: repeat(3, 1fr);
    margin: 79px 20px 0;
    row-gap: 111px;
  }
}

.partner {
  display: flex;
  justify-content: center;
}
