@use 'reset';
@use 'abstracts/variables';
@use 'abstracts/breakpoints' as *;

/* ==================================================
Utilities
================================================== */
@use 'utilities/box';
@use 'utilities/center-content';
@use 'utilities/visually-hidden';
@use 'utilities/full-bleed';
@use 'utilities/postion_content';
@use 'utilities/responsive-grid';
@use 'utilities/skip-link';
@use 'utilities/sidebar';
@use 'utilities/stack';
@use 'utilities/stack-sections';
@use 'utilities/switcher';
@use 'utilities/wrapper';
/* ==================================================
Components
================================================== */
// TODO add components
@use 'components/burger-menu';
@use 'components/site-head';
@use 'components/footer';

// TODO remove draft styles below as work completed
@use 'components/draft/style-header';
@use 'components/draft/style-partners';
@use 'components/draft/style-testimonials';

@use 'components/draft/style_timeline';

/* ==================================================
Pages
================================================== */
// @use '../pages/faq/faq';
// @use '../pages/index/home';
// @use '../pages/about/about';
// @use '../pages/support/support';
// @use '../pages/product/product';

/* ==================================================
Global Styles
================================================== */
// TODO Add global styles here (i.e. on raw html elements, such as body, h1 etc):
@import url('https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,400;0,500;0,700;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Andada+Pro:wght@500;700&display=swap');

body {
  font-family: var(--ff-body);
  color: var(--clr-black);
  // font-size: var(--step-0);
}

a {
  color: var(--clr-primary-dark);
  text-decoration: none;
}

:focus-visible {
  opacity: 1 !important;
  outline: 4px solid var(--outline, currentColor);
  outline-offset: 4px;
}

:focus:not(:focus-visible) {
  outline: transparent;
}

.main-link {
  text-decoration: underline;
  text-decoration-thickness: 1px;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

.main-link:hover {
  color: var(--clr-purple);
}

.container-content {
  margin: 0 auto;
  width: 100%;
  max-width: 1340px;

  @include respond-to('medium') {
    padding: 0 20px;
  }
}

.container {
  max-width: 1294px;
  margin: 0 auto;
}

.global-header-1 {
  font-weight: 700;
  font-size: 32px;
  line-height: 100%;
  font-family: var(--ff-headings);
  color: var(--clr-black);

  @include respond-to('medium') {
    font-size: 40px;
  }
}
.global-header-2 {
  font-weight: 700;
  font-size: 24px;
  line-height: 110%;
  font-family: var(--ff-headings);
  color: var(--clr-black);

  @include respond-to('medium') {
    font-size: 32px;
  }
}
.global-header-3 {
  font-family: var(--ff-body);
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 110%;
  font-family: var(--ff-headings);
  color: var(--clr-black);

  @include respond-to('medium') {
    font-size: 24px;
  }
}

.global-paragraph-1 {
  font-family: var(--ff-body);
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;

  @include respond-to('medium') {
    font-size: 20px;
  }
}
.global-paragraph-2 {
  font-family: var(--ff-body);
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
}

.fw-med {
  font-weight: 500;
}
.fw-bold {
  font-weight: 700;
}
.italic {
  font-style: italic;
}

.btn-main {
  display: flex;
  align-items: center;
  gap: 8px;
  max-inline-size: fit-content;
  padding: 10px 32px;
  text-align: center;

  font-family: var(--ff-body);
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: var(--clr-white);
  background: var(--clr-purple);
  border: none;
  border-radius: var(--radius);
  cursor: pointer;
  transition: color 250ms, background-color 250ms;

  .btn-svg {
    fill: var(--clr-white);
  }

  &:hover {
    background-color: var(--clr-purple-light);
    transition: color 250ms, background-color 250ms;
  }
  &:focus-visible {
    opacity: 1 !important;
    outline: 4px solid var(--clr-purple-light);
    outline-offset: 4px;
  }
}
.btn-secondary {
  display: flex;
  align-items: center;
  max-inline-size: fit-content;
  gap: 8px;

  font-family: var(--ff-body);
  font-weight: 700;
  font-size: 16px;

  padding: 10px 32px;
  color: var(--clr-black);
  background: none;
  border: 1px solid var(--clr-black);
  border-radius: var(--radius);
  margin-top: 30px;
  text-align: center;
  cursor: pointer;
  transition: color 250ms, background-color 250ms;

  .btn-svg {
    fill: #000000;
    transition: color 250ms, background-color 250ms;
  }

  &:hover {
    border: 1px solid var(--clr-purple-dark);
    color: var(--clr-purple-dark);
    transition: color 250ms, background-color 250ms;

    .btn-svg {
      fill: var(--clr-purple-dark);
      transition: color 250ms, background-color 250ms;
    }
  }
}

.lazy-img {
  filter: blur(5px);
}
