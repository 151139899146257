@use 'src/scss/abstracts/_breakpoints.scss' as *;

.header {
  // padding-bottom: 56px;
  // position: relative;
  width: 100%;
  max-width: 1340px;
  margin: 0 auto;
  @include respond-to('medium') {
    padding: 0 20px;
  }
}
.nav-bar {
  display: flex;
  justify-content: space-between;
  padding: 0 72px;
}
.nav-links {
  display: flex;
  align-items: center;
  gap: 41px;
}

.header-content-container {
  position: absolute;
  padding: 30px 58px 20px 17px;
  top: 18%;
  left: 8%;
  background: #ffeec0;
  width: 85%;
  @include respond-to('large') {
    max-width: 700px;
    top: 45%;
    left: 10%;
    padding: 50px 0 50px 30px;
  }
}
.header-p {
  margin: 30px 0;
  @include respond-to('large') {
    padding-right: 44px;
  }
}

.hero-image-container {
  @include respond-to('large') {
    float: right;
    padding-right: 72px;
    margin-right: 15px;
    width: 1000px;
    height: 520px;
  }
}

.hero-image-container img {
  width: 100%;
  height: 100%;
}
