/* Hamburger button creation */
.burger-menu__trigger {
  display: none;
}

.burger-menu__bar,
.burger-menu__bar::before,
.burger-menu__bar::after {
  position: absolute;
  display: block;
  inset-inline-start: 50%;
  inline-size: 24px;
  block-size: 3px;
  border: 1px solid var(--clr-black);
  border-radius: 3px;
  margin-inline-start: -12px;
  background: var(--clr-black);
  transition: transform 350ms ease-in-out;
}

.burger-menu__bar {
  opacity: 1;
  inset-block-start: 50%;
  transition: opacity 300ms;
}

.burger-menu__bar::before,
.burger-menu__bar::after {
  content: '';
}

.burger-menu__bar::before {
  inset-block-start: -8px;
}

.burger-menu__bar::after {
  inset-block-end: -8px;
}

// Hamburger button size and positioning
.burger-menu[enabled='true'] .burger-menu__trigger {
  position: relative;
  display: block;
  inline-size: 2rem;
  block-size: 2rem;
  border: 0;
  background: transparent;
  cursor: pointer;
}

// Hamburger menu panel size and positioning
.burger-menu[enabled='true'] .burger-menu__panel {
  position: absolute;
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  inset-inline-start: 0;
  inline-size: 100%;
  block-size: 100%;
  padding-block-start: max(4vh, 2rem);
  padding-inline: var(--space-l);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  transition: opacity 300ms;
}

// Hamburger menu panel content size and positioning
.burger-menu[enabled='true'] .navigation {
  position: relative;
  padding-block: max(8vh, 4rem);
  box-shadow: var(--shadow-elevation-medium);
  background-color: var(--clr-white);
  border-radius: 0.5rem;

  ul {
    flex-direction: column;
  }
}

// Hamburger menu panel transitions
.burger-menu[enabled='true'][status='open'] .burger-menu__panel {
  visibility: visible;
  opacity: 1;
  transition: opacity 200ms ease;
}

.burger-menu[enabled='true'][status='closed'] .burger-menu__panel > * {
  opacity: 0;
  transform: translateY(5rem);
}

.burger-menu[enabled='true'][status='open'] .burger-menu__panel > * {
  opacity: 1;
  transform: translateY(0);
  transition: transform 300ms cubic-bezier(0.17, 0.67, 0, 0.87) 100ms,
    opacity 200ms ease 100ms;
}

.burger-menu[enabled='true'][status='open'] .burger-menu__bar::before {
  inset-block-start: 0;
  transform: rotate(45deg);
}

.burger-menu[enabled='true'][status='open'] .burger-menu__bar::after {
  inset-block-start: 0;
  transform: rotate(-45deg);
}

.burger-menu[enabled='true'][status='open'] .burger-menu__bar {
  border-color: transparent;
  background: transparent;
  transform: rotate(180deg);
}
