@use 'src/scss/abstracts/_breakpoints.scss' as *;

.testimonials-container {
  position: relative;
  max-width: 1000px;
  // max-width: 857px;
  font-family: var(--ff-body);
  @include respond-to('large') {
    margin: 70px auto 0 auto;
  }

  &.about-founders {
    max-width: 1200px;
    background: #e5fce3;
  }
}
.testimonials-container h2 {
  text-align: center;
  font-family: var(--ff-headings);
  font-weight: bold;
}
