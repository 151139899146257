@import "https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,400;0,500;0,700;1,400&display=swap";
@import "https://fonts.googleapis.com/css2?family=Andada+Pro:wght@500;700&display=swap";
*, :before, :after {
  box-sizing: border-box;
}

html:focus-within {
  scroll-behavior: smooth;
}

body, h1, h2, h3, h4, p, figure, blockquote, dl, dd, ul, ol, button {
  margin: 0;
}

body {
  min-block-size: 100vh;
  color: #000;
  text-rendering: optimizespeed;
  -webkit-font-smoothing: antialiased;
  background-color: #fff;
  line-height: 1.6;
  overflow-y: scroll;
}

h1, h2, h3, h4 {
  line-height: 1.1;
}

:where(ul[role="list"]), :where(ul[role="tablist"]), :where(ol[role="list"]), :where(ol[role="list"]) {
  padding-inline: 0;
  list-style-type: none;
}

ul {
  list-style-type: none;
}

a:not([class]) {
  -webkit-text-decoration-skip: ink;
  text-decoration-skip-ink: auto;
}

img, picture, video, canvas, svg {
  max-inline-size: 100%;
  display: block;
}

img[width][height] {
  block-size: auto;
}

input, button, textarea, select {
  font: inherit;
}

[id] {
  scroll-margin-top: 2ex;
}

@media (prefers-reduced-motion: reduce) {
  *, :before, :after {
    scroll-behavior: auto !important;
    transition-duration: .01ms !important;
    animation-duration: .01ms !important;
    animation-iteration-count: 1 !important;
  }
}

:root {
  --clr-purple-dark: #533beb;
  --clr-purple: #6e59f7;
  --clr-purple-light: #8472f7;
  --clr-purple-lighter: #edebfa;
  --clr-green-dark: #30802b;
  --clr-green: #3cab35;
  --clr-green-light: #a0e69c;
  --clr-green-lighter: #e5fce3;
  --clr-black: #19181a;
  --clr-gray-dark: #3d3b40;
  --clr-gray-light: #b8b6ba;
  --clr-white: #fbfafc;
  --clr-primary-dark: #533beb;
  --clr-primary: #6e59f7;
  --clr-primary-light: #8472f7;
  --clr-primary-lighter: #edebfa;
  --clr-secondary-dark: #30802b;
  --clr-secondary: #3cab35;
  --clr-secondary-light: #a0e69c;
  --clr-secondary-lighter: #e5fce3;
  --ff-headings: "Andada Pro", Constantia, ‘Lucida Bright’, Lucidabright, ‘Lucida Serif’, Lucida, ‘DejaVu Serif’, ‘Bitstream Vera Serif’, ‘Liberation Serif’, Georgia, serif;
  --ff-body: "Karla", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  --space-xxs: clamp(.5rem, calc(.46rem + .18vw), .63rem);
  --space-xs: clamp(.75rem, calc(.7rem + .27vw), .94rem);
  --space-s: clamp(1rem, calc(.93rem + .36vw), 1.25rem);
  --space-m: clamp(1.5rem, calc(1.39rem + .54vw), 1.88rem);
  --space-l: clamp(2rem, calc(1.86rem + .71vw), 2.5rem);
  --space-xl: clamp(3rem, calc(2.79rem + 1.07vw), 3.75rem);
  --space-xxl: clamp(4rem, calc(3.71rem + 1.43vw), 5rem);
  --step--1: clamp(.83rem, calc(.78rem + .29vw), 1rem);
  --step-0: clamp(1rem, calc(.91rem + .43vw), 1.25rem);
  --step-1: clamp(1.2rem, calc(1.07rem + .63vw), 1.56rem);
  --step-2: clamp(1.44rem, calc(1.26rem + .89vw), 1.95rem);
  --step-3: clamp(1.73rem, calc(1.48rem + 1.24vw), 2.44rem);
  --step-4: clamp(2.07rem, calc(1.73rem + 1.7vw), 3.05rem);
  --step-5: clamp(2.49rem, calc(2.03rem + 2.31vw), 3.82rem);
  --step-6: clamp(2.99rem, calc(2.37rem + 3.1vw), 4.77rem);
  --shadow-color: 68, 68, 68;
  --shadow-elevation-low: .3px .5px .8px rgba(var(--shadow-color, 7, 0%, 14%), .14), .6px 1px 1.6px -.5px rgba(var(--shadow-color, 7, 0%, 14%), .28), 1.2px 2.1px 3.3px -1px rgba(var(--shadow-color, 7, 0%, 14%), .42);
  --shadow-elevation-medium: .3px .5px .8px rgba(var(--shadow-color, 7, 0%, 14%), .15), 1.3px 2.3px 3.6px -.3px rgba(var(--shadow-color, 7, 0%, 14%), .26), 2.8px 4.9px 7.6px -.7px rgba(var(--shadow-color, 7, 0%, 14%), .36), 6.1px 10.7px 16.6px -1px rgba(var(--shadow-color, 7, 0%, 14%), .47);
  --radius: .625rem;
}

.box {
  padding-block: var(--bespoke-padding-block, var(--space-s, 1rem));
  padding-inline: var(--bespoke-padding-inline, var(--space-s, 1rem));
  color: var(--bespoke-color, var(--clr-black));
}

.box * {
  color: inherit;
}

.center-content {
  --min: 1rem;
  --container-max: 1440px;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: minmax(var(--min, 1rem), 1fr) minmax(320px, var(--container-max)) minmax(var(--min, 1rem), 1fr);
  display: grid;
}

.center-content > * {
  grid-column: 2;
}

.visually-hidden {
  inline-size: 1px;
  block-size: 0;
  clip: rect(0 0 0 0);
  white-space: nowrap;
  border: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.visually-hidden:hover, .visually-hidden:active, .visually-hidden:focus {
  inline-size: auto;
  block-size: auto;
  white-space: normal;
  clip: auto;
  margin: 0;
  position: static;
  overflow: visible;
}

.full-bleed {
  inline-size: 100vw;
  margin-inline-start: calc(50% - 50vw);
}

.grid {
  gap: var(--gap, 1rem);
  display: grid;
}

.flex {
  gap: var(--gap, 1rem);
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.flow > :where(:not(:first-child)) {
  margin-top: var(--flow-space, 1rem);
}

.responsive-grid {
  grid-gap: var(--space-xxl);
  grid-template-columns: repeat(auto-fit, minmax(min(14.5rem, 100%), 1fr));
  display: grid;
}

.skip-link {
  text-transform: uppercase;
  color: #000;
  background: #fff;
  padding-block: .7rem .5rem;
  padding-inline: 1rem;
  font-weight: 700;
  text-decoration: none;
  display: inline-block;
  position: absolute;
  inset-block-start: 1rem;
  inset-inline-start: 1rem;
}

.skip-link:hover {
  color: #fff;
  background: #000;
}

.skip-link:not(:focus) {
  inline-size: 1px;
  block-size: auto;
  white-space: nowrap;
  clip: rect(0 0 0 0);
  border: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

main:focus {
  outline: none;
}

.with-sidebar {
  gap: calc(var(--space-xxl) * 2);
  flex-wrap: wrap;
  display: flex;
}

.with-sidebar > :first-child {
  flex-grow: 1;
}

.with-sidebar > :last-child {
  min-inline-size: 70%;
  flex-grow: 999;
  flex-basis: 0;
}

.stack > * + * {
  margin-block-start: var(--margin-start, var(--space-s, 1em));
  margin-inline-start: var(--margin-start, var(--space-s, 1em));
  margin-inline-end: var(--margin-start, var(--space-s, 1em));
}

body.stack-sections > * + * {
  margin-block-start: var(--space-xl, 1em);
}

.stack-sections > * + * {
  margin-block-start: var(--space-xxl, 1em);
}

.switcher {
  gap: var(--bespoke-gap, var(--space-xl, 1rem));
  flex-wrap: wrap;
  display: flex;
}

.switcher > * {
  flex-basis: calc((var(--min-basis, 45rem)  - 100%) * 999);
  flex-grow: 1;
}

.switcher > :nth-last-child(n+5), .switcher > :nth-last-child(n+5) ~ * {
  flex-basis: 100%;
}

.wrapper {
  --min-inline-padding: calc(var(--space-xs) * 1);
  --ideal-inline-padding: 15%;
  --max-inline-padding: calc(var(--space-xxl) * 5);
  padding-inline: clamp(var(--min-inline-padding), var(--ideal-inline-padding), var(--max-inline-padding));
}

.burger-menu__trigger {
  display: none;
}

.burger-menu__bar, .burger-menu__bar:before, .burger-menu__bar:after {
  inline-size: 24px;
  block-size: 3px;
  border: 1px solid var(--clr-black);
  background: var(--clr-black);
  border-radius: 3px;
  margin-inline-start: -12px;
  transition: transform .35s ease-in-out;
  display: block;
  position: absolute;
  inset-inline-start: 50%;
}

.burger-menu__bar {
  opacity: 1;
  transition: opacity .3s;
  inset-block-start: 50%;
}

.burger-menu__bar:before, .burger-menu__bar:after {
  content: "";
}

.burger-menu__bar:before {
  inset-block-start: -8px;
}

.burger-menu__bar:after {
  inset-block-end: -8px;
}

.burger-menu[enabled="true"] .burger-menu__trigger {
  inline-size: 2rem;
  block-size: 2rem;
  cursor: pointer;
  background: none;
  border: 0;
  display: block;
  position: relative;
}

.burger-menu[enabled="true"] .burger-menu__panel {
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  inline-size: 100%;
  block-size: 100%;
  padding-block-start: max(4vh, 2rem);
  padding-inline: var(--space-l);
  -webkit-overflow-scrolling: touch;
  transition: opacity .3s;
  position: absolute;
  inset-inline-start: 0;
  overflow-y: auto;
}

.burger-menu[enabled="true"] .navigation {
  box-shadow: var(--shadow-elevation-medium);
  background-color: var(--clr-white);
  border-radius: .5rem;
  padding-block: max(8vh, 4rem);
  position: relative;
}

.burger-menu[enabled="true"] .navigation ul {
  flex-direction: column;
}

.burger-menu[enabled="true"][status="open"] .burger-menu__panel {
  visibility: visible;
  opacity: 1;
  transition: opacity .2s;
}

.burger-menu[enabled="true"][status="closed"] .burger-menu__panel > * {
  opacity: 0;
  transform: translateY(5rem);
}

.burger-menu[enabled="true"][status="open"] .burger-menu__panel > * {
  opacity: 1;
  transition: transform .3s cubic-bezier(.17, .67, 0, .87) .1s, opacity .2s .1s;
  transform: translateY(0);
}

.burger-menu[enabled="true"][status="open"] .burger-menu__bar:before {
  inset-block-start: 0;
  transform: rotate(45deg);
}

.burger-menu[enabled="true"][status="open"] .burger-menu__bar:after {
  inset-block-start: 0;
  transform: rotate(-45deg);
}

.burger-menu[enabled="true"][status="open"] .burger-menu__bar {
  background: none;
  border-color: #0000;
  transform: rotate(180deg);
}

.site-head {
  --outline: var(--clr-purple-dark);
  justify-content: space-between;
  align-items: center;
  gap: var(--space-xl);
  margin: 0 auto;
  margin-block: 1rem;
  max-width: 1300px;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  display: flex;
}

.logo {
  max-width: 147px;
  max-height: 37px;
}

.logo img {
  height: 28px;
  width: 147px;
}

.nav-button {
  font-size: 16px;
  font-weight: 500;
  font-family: var(--ff-body);
  max-inline-size: fit-content;
  text-align: center;
  color: var(--clr-white);
  background: var(--clr-purple);
  border-radius: var(--radius);
  cursor: pointer;
  border: none;
  align-items: center;
  gap: 8px;
  padding: 10px 32px;
  line-height: 22px;
  transition: color .25s, background-color .25s;
  display: flex;
}

.nav-button .btn-svg {
  fill: var(--clr-white);
}

.nav-button:hover {
  background-color: var(--clr-purple-light);
  transition: color .25s, background-color .25s;
}

.navigation ul {
  align-items: center;
  gap: var(--space-xxl);
  flex-wrap: wrap;
  display: flex;
}

.navigation ul a:not([class]) {
  color: var(--clr-black);
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  transition: color .25s, background-color .25s;
  display: block;
}

.navigation ul a:not([class]):hover, .navigation ul a:not([class]):focus {
  color: var(--clr-purple-dark);
}

.navigation ul a[aria-current] {
  color: var(--clr-purple-dark);
  font-weight: 700;
}

.footer {
  background-color: var(--clr-black);
  text-align: left;
  grid-column: -1 / 1;
  padding: 33px 16px;
}

@media (min-width: 70rem) {
  .footer {
    padding: 48px 67px;
  }
}

.footer a {
  color: var(--clr-white);
  font-size: 16px;
  font-weight: 500;
}

.footer nav {
  max-width: 1300px;
  flex-direction: column;
  margin: 0 auto;
  display: flex;
}

@media (min-width: 70rem) {
  .footer nav {
    align-items: center;
    gap: calc(var(--space-xxl) * 2);
    flex-direction: row;
  }
}

.footer .footer-logo-container {
  width: 47%;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  margin-bottom: 32px;
  display: flex;
}

@media (min-width: 70rem) {
  .footer .footer-logo-container {
    height: 110px;
    margin-bottom: 0;
  }
}

.footer .footer-logo {
  height: 37px;
  width: 144px;
}

.footer .list-container {
  width: 100%;
  justify-content: space-between;
  margin-bottom: 24px;
  display: flex;
}

@media (min-width: 70rem) {
  .footer .list-container {
    width: 60%;
    margin-bottom: 0;
  }
}

.footer .footer-row {
  width: 50%;
}

.footer .footer-row li {
  margin-bottom: 26px;
}

.footer .footer-row li:last-child {
  margin-bottom: 0;
}

.footer .footer-row:first-child {
  position: relative;
}

@media (min-width: 70rem) {
  .footer .footer-row:first-child:after {
    content: "";
    width: 1px;
    height: 100%;
    background-color: #838383;
    display: block;
    position: absolute;
    top: 50%;
    left: 65%;
    transform: translate(0, -50%);
  }
}

.footer .linkedin-img {
  height: 50px;
  width: 50px;
}

.footer .icon-links-large {
  margin-left: -7px;
  display: none;
}

@media (min-width: 70rem) {
  .footer .icon-links-large {
    display: block;
  }
}

.footer .icon-links-mobile {
  margin-left: -7px;
  display: block;
}

@media (min-width: 70rem) {
  .footer .icon-links-mobile {
    display: none;
  }
}

.footer .icon-link-mobile {
  height: 40px;
  width: 40px;
  display: inline;
}

.footer .footer-contact {
  color: var(--clr-white);
  font-weight: 700;
  margin-bottom: 0 !important;
}

.footer .footer-contact-link {
  color: var(--clr-white);
  font-size: 14px;
  font-weight: 400;
  text-decoration: underline;
}

.header {
  width: 100%;
  max-width: 1340px;
  margin: 0 auto;
}

@media (min-width: 55rem) {
  .header {
    padding: 0 20px;
  }
}

.nav-bar {
  justify-content: space-between;
  padding: 0 72px;
  display: flex;
}

.nav-links {
  align-items: center;
  gap: 41px;
  display: flex;
}

.header-content-container {
  width: 85%;
  background: #ffeec0;
  padding: 30px 58px 20px 17px;
  position: absolute;
  top: 18%;
  left: 8%;
}

@media (min-width: 70rem) {
  .header-content-container {
    max-width: 700px;
    padding: 50px 0 50px 30px;
    top: 45%;
    left: 10%;
  }
}

.header-p {
  margin: 30px 0;
}

@media (min-width: 70rem) {
  .header-p {
    padding-right: 44px;
  }

  .hero-image-container {
    float: right;
    width: 1000px;
    height: 520px;
    margin-right: 15px;
    padding-right: 72px;
  }
}

.hero-image-container img {
  width: 100%;
  height: 100%;
}

.partners-container {
  margin: 60px 0;
}

@media (min-width: 70rem) {
  .partners-container {
    margin-top: 131px;
  }
}

.partners-container h2 {
  text-align: center;
  font-family: var(--ff-headings);
  margin-bottom: 30px;
  font-weight: bold;
}

.partners-grid {
  display: grid;
}

@media (min-width: 70rem) {
  .partners-grid {
    grid-template-columns: repeat(3, 1fr);
    row-gap: 111px;
    margin: 79px 20px 0;
  }
}

.partner {
  justify-content: center;
  display: flex;
}

.testimonials-container {
  max-width: 1000px;
  font-family: var(--ff-body);
  position: relative;
}

@media (min-width: 70rem) {
  .testimonials-container {
    margin: 70px auto 0;
  }
}

.testimonials-container.about-founders {
  max-width: 1200px;
  background: #e5fce3;
}

.testimonials-container h2 {
  text-align: center;
  font-family: var(--ff-headings);
  font-weight: bold;
}

.timeline-container {
  text-align: left;
  padding-top: 3em;
}

.timeline-container ul {
  position: relative;
}

.timeline-container ul li {
  width: 3px;
  background: #6e59f7;
  padding-top: 150px;
  list-style-type: none;
  position: relative;
}

@media (min-width: 70rem) {
  .timeline-container ul li {
    margin: 0 auto;
  }
}

.timeline-container ul li div {
  width: 24px;
  height: 24px;
  z-index: 1;
  background: #6e59f7;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.timeline-container ul li div:after {
  content: "";
  width: 70px;
  height: 1px;
  border: 1px dashed #6e59f7;
  position: absolute;
  top: 50%;
}

@media (min-width: 70rem) {
  .timeline-container ul li div:after {
    width: 100px;
  }
}

.timeline-container ul li time {
  width: 50px;
  font-family: Andada Pro;
  font-size: 24px;
  font-weight: 500;
  position: absolute;
  left: 70px;
}

@media (min-width: 40rem) {
  .timeline-container ul li time {
    font-size: 32px;
  }
}

.timeline-container ul li p {
  width: 70vw;
  font-family: Karla;
  font-size: var(--step-0);
  font-weight: 400;
  position: absolute;
  top: 40px;
}

@media (min-width: 40rem) {
  .timeline-container ul li p {
    top: 70px;
  }
}

@media (min-width: 70rem) {
  .timeline-container ul li p {
    width: 350px;
  }

  .timeline-container ul li:nth-child(2n+1) {
    text-align: right;
  }
}

.timeline-container ul li:nth-child(2n+1):before {
  border-width: 8px 16px 8px 0;
  border-color: #0000 #f45b69 #0000 #0000;
  left: -15px;
}

@media (min-width: 70rem) {
  .timeline-container ul li:nth-child(2n+1) div:after {
    right: 35px;
  }
}

.timeline-container ul li:nth-child(2n+1) time {
  top: -34px;
}

@media (min-width: 70rem) {
  .timeline-container ul li:nth-child(2n+1) time {
    right: -10em;
  }
}

.timeline-container ul li:nth-child(2n+1) p {
  left: 40px;
}

@media (min-width: 40rem) {
  .timeline-container ul li:nth-child(2n+1) p {
    left: 70px;
  }
}

@media (min-width: 70rem) {
  .timeline-container ul li:nth-child(2n+1) p {
    top: -12px;
    left: -25em;
  }
}

.timeline-container ul li:nth-child(2n):before {
  border-width: 8px 0 8px 16px;
  border-color: #0000 #0000 #0000 #f45b69;
  right: -15px;
}

@media (min-width: 70rem) {
  .timeline-container ul li:nth-child(2n) div:after {
    left: 35px;
  }
}

.timeline-container ul li:nth-child(2n) time {
  position: absolute;
  top: -34px;
}

@media (min-width: 70rem) {
  .timeline-container ul li:nth-child(2n) time {
    left: -6em;
  }
}

.timeline-container ul li:nth-child(2n) p {
  left: 40px;
}

@media (min-width: 40rem) {
  .timeline-container ul li:nth-child(2n) p {
    left: 70px;
  }
}

@media (min-width: 70rem) {
  .timeline-container ul li:nth-child(2n) p {
    top: -12px;
    left: unset;
    position: absolute;
    right: -25em;
  }
}

.timeline-container ul li:last-child {
  background: none;
}

body {
  font-family: var(--ff-body);
  color: var(--clr-black);
}

a {
  color: var(--clr-primary-dark);
  text-decoration: none;
}

:focus-visible {
  outline: 4px solid var(--outline, currentColor);
  outline-offset: 4px;
  opacity: 1 !important;
}

:focus:not(:focus-visible) {
  outline: #0000;
}

.main-link {
  text-decoration-skip-ink: none;
  text-decoration: underline;
  text-decoration-thickness: 1px;
}

.main-link:hover {
  color: var(--clr-purple);
}

.container-content {
  width: 100%;
  max-width: 1340px;
  margin: 0 auto;
}

@media (min-width: 55rem) {
  .container-content {
    padding: 0 20px;
  }
}

.container {
  max-width: 1294px;
  margin: 0 auto;
}

.global-header-1 {
  font-size: 32px;
  font-weight: 700;
  line-height: 100%;
  font-family: var(--ff-headings);
  color: var(--clr-black);
}

@media (min-width: 55rem) {
  .global-header-1 {
    font-size: 40px;
  }
}

.global-header-2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 110%;
  font-family: var(--ff-headings);
  color: var(--clr-black);
}

@media (min-width: 55rem) {
  .global-header-2 {
    font-size: 32px;
  }
}

.global-header-3 {
  font-family: var(--ff-body);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%;
  font-family: var(--ff-headings);
  color: var(--clr-black);
}

@media (min-width: 55rem) {
  .global-header-3 {
    font-size: 24px;
  }
}

.global-paragraph-1 {
  font-family: var(--ff-body);
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
}

@media (min-width: 55rem) {
  .global-paragraph-1 {
    font-size: 20px;
  }
}

.global-paragraph-2 {
  font-family: var(--ff-body);
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
}

.fw-med {
  font-weight: 500;
}

.fw-bold {
  font-weight: 700;
}

.italic {
  font-style: italic;
}

.btn-main {
  max-inline-size: fit-content;
  text-align: center;
  font-family: var(--ff-body);
  color: var(--clr-white);
  background: var(--clr-purple);
  border-radius: var(--radius);
  cursor: pointer;
  border: none;
  align-items: center;
  gap: 8px;
  padding: 10px 32px;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  transition: color .25s, background-color .25s;
  display: flex;
}

.btn-main .btn-svg {
  fill: var(--clr-white);
}

.btn-main:hover {
  background-color: var(--clr-purple-light);
  transition: color .25s, background-color .25s;
}

.btn-main:focus-visible {
  outline: 4px solid var(--clr-purple-light);
  outline-offset: 4px;
  opacity: 1 !important;
}

.btn-secondary {
  max-inline-size: fit-content;
  font-family: var(--ff-body);
  color: var(--clr-black);
  border: 1px solid var(--clr-black);
  border-radius: var(--radius);
  text-align: center;
  cursor: pointer;
  background: none;
  align-items: center;
  gap: 8px;
  margin-top: 30px;
  padding: 10px 32px;
  font-size: 16px;
  font-weight: 700;
  transition: color .25s, background-color .25s;
  display: flex;
}

.btn-secondary .btn-svg {
  fill: #000;
  transition: color .25s, background-color .25s;
}

.btn-secondary:hover {
  border: 1px solid var(--clr-purple-dark);
  color: var(--clr-purple-dark);
  transition: color .25s, background-color .25s;
}

.btn-secondary:hover .btn-svg {
  fill: var(--clr-purple-dark);
  transition: color .25s, background-color .25s;
}

.lazy-img {
  filter: blur(5px);
}

/*# sourceMappingURL=index.4237c6a8.css.map */
