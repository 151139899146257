@use 'src/scss/abstracts/_breakpoints.scss' as *;

.footer {
  grid-column: -1 / 1;
  background-color: var(--clr-black);
  padding: 33px 16px;
  text-align: left;

  @include respond-to('large') {
    padding: 48px 67px;
  }

  a {
    color: var(--clr-white);
    font-size: 16px;
    font-weight: 500;
  }

  nav {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    max-width: 1300px;

    @include respond-to('large') {
      flex-direction: row;
      align-items: center;
      gap: calc(var(--space-xxl) * 2);
    }
  }

  .footer-logo-container {
    width: 47%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    margin-bottom: 32px;

    @include respond-to('large') {
      height: 110px;
      margin-bottom: 0px;
    }
  }
  .footer-logo {
    height: 37px;
    width: 144px;
  }
  .list-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;

    @include respond-to('large') {
      width: 60%;
      margin-bottom: 0px;
    }
  }
  .footer-row {
    width: 50%;
    li {
      margin-bottom: 26px;

      &:last-child {
        margin-bottom: 0px;
      }
    }

    &:first-child {
      position: relative;
      &::after {
        @include respond-to('large') {
          content: '';
          position: absolute;
          display: block;
          width: 1px;
          height: 100%;
          background-color: #838383;
          top: 50%;
          left: 65%;
          transform: translate(0, -50%);
        }
      }
    }
  }
  .linkedin-img {
    height: 50px;
    width: 50px;
  }
  .icon-links-large {
    display: none;
    margin-left: -7px;

    @include respond-to('large') {
      display: block;
    }
  }
  .icon-links-mobile {
    display: block;
    margin-left: -7px;
    @include respond-to('large') {
      display: none;
    }
  }
  .icon-link-mobile {
    display: inline;
    height: 40px;
    width: 40px;
  }

  .footer-contact {
    color: var(--clr-white);
    font-weight: 700;
    margin-bottom: 0px !important;
  }

  .footer-contact-link {
    text-decoration: underline;
    color: var(--clr-white);
    font-weight: 400;
    font-size: 14px;
  }
}
