:root {
  /* Colors */

  --clr-purple-dark: #533beb;
  --clr-purple: #6e59f7;
  --clr-purple-light: #8472f7;
  --clr-purple-lighter: #edebfa;

  --clr-green-dark: #30802b;
  --clr-green: #3cab35;
  --clr-green-light: #a0e69c;
  --clr-green-lighter: #e5fce3;

  --clr-black: #19181a;
  --clr-gray-dark: #3d3b40;
  --clr-gray-light: #b8b6ba;
  --clr-white: #fbfafc;

  --clr-primary-dark: #533beb;
  --clr-primary: #6e59f7;
  --clr-primary-light: #8472f7;
  --clr-primary-lighter: #edebfa;

  --clr-secondary-dark: #30802b;
  --clr-secondary: #3cab35;
  --clr-secondary-light: #a0e69c;
  --clr-secondary-lighter: #e5fce3;

  /* Font styles */
  --ff-headings: 'Andada Pro', Constantia, ‘Lucida Bright’, Lucidabright,
    ‘Lucida Serif’, Lucida, ‘DejaVu Serif’, ‘Bitstream Vera Serif’,
    ‘Liberation Serif’, Georgia, serif;
  --ff-body: 'Karla', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;

  /* Spacing */
  --space-xxs: clamp(0.5rem, calc(0.46rem + 0.18vw), 0.63rem);
  --space-xs: clamp(0.75rem, calc(0.7rem + 0.27vw), 0.94rem);
  --space-s: clamp(1rem, calc(0.93rem + 0.36vw), 1.25rem);
  --space-m: clamp(1.5rem, calc(1.39rem + 0.54vw), 1.88rem);
  --space-l: clamp(2rem, calc(1.86rem + 0.71vw), 2.5rem);
  --space-xl: clamp(3rem, calc(2.79rem + 1.07vw), 3.75rem);
  --space-xxl: clamp(4rem, calc(3.71rem + 1.43vw), 5rem);

  /* Font sizes */
  --step--1: clamp(0.83rem, calc(0.78rem + 0.29vw), 1rem);
  --step-0: clamp(1rem, calc(0.91rem + 0.43vw), 1.25rem);
  --step-1: clamp(1.2rem, calc(1.07rem + 0.63vw), 1.56rem);
  --step-2: clamp(1.44rem, calc(1.26rem + 0.89vw), 1.95rem);
  --step-3: clamp(1.73rem, calc(1.48rem + 1.24vw), 2.44rem);
  --step-4: clamp(2.07rem, calc(1.73rem + 1.7vw), 3.05rem);
  --step-5: clamp(2.49rem, calc(2.03rem + 2.31vw), 3.82rem);
  --step-6: clamp(2.99rem, calc(2.37rem + 3.1vw), 4.77rem);

  /* Box shadow */
  --shadow-color: 68, 68, 68;
  --shadow-elevation-low: 0.3px 0.5px 0.8px
      rgba(var(--shadow-color, 7, 0%, 14%), 0.14),
    0.6px 1px 1.6px -0.5px rgba(var(--shadow-color, 7, 0%, 14%), 0.28),
    1.2px 2.1px 3.3px -1px rgba(var(--shadow-color, 7, 0%, 14%), 0.42);
  --shadow-elevation-medium: 0.3px 0.5px 0.8px
      rgba(var(--shadow-color, 7, 0%, 14%), 0.15),
    1.3px 2.3px 3.6px -0.3px rgba(var(--shadow-color, 7, 0%, 14%), 0.26),
    2.8px 4.9px 7.6px -0.7px rgba(var(--shadow-color, 7, 0%, 14%), 0.36),
    6.1px 10.7px 16.6px -1px rgba(var(--shadow-color, 7, 0%, 14%), 0.47);

  /* border radius */
  --radius: 0.625rem;
}
