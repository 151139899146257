/* Skip to main content */

.skip-link {
  position: absolute;
  display: inline-block;
  inset-block-start: 1rem;
  inset-inline-start: 1rem;
  background: #fff;
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase;
  color: #000;
  padding-block: 0.7rem 0.5rem;
  padding-inline: 1rem;
}

.skip-link:hover {
  background: #000;
  color: #fff;
}

.skip-link:not(:focus) {
  position: absolute;
  inline-size: 1px;
  block-size: auto;
  padding: 0;
  border: 0;
  overflow: hidden;
  margin: 0;
  white-space: nowrap;
  clip: rect(0 0 0 0);
}

/* Remove necessary focus style as main is the :target */
main:focus {
  outline: none;
}
