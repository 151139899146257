.site-head {
  --outline: var(--clr-purple-dark);
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: var(--space-xl);
  // TODO: replace with flow utility class?
  margin-block: 1rem;
  margin-bottom: 2rem;
  max-width: 1300px;
}

.logo {
  max-width: 147px;
  max-height: 37px;
  img {
    height: 28px;
    width: 147px;
  }
}

.nav-button {
  font-size: 16px;
  font-weight: 500;
  font-family: var(--ff-body);
  display: flex;
  align-items: center;
  gap: 8px;
  max-inline-size: fit-content;
  padding: 10px 32px;
  text-align: center;

  line-height: 22px;
  color: var(--clr-white);
  background: var(--clr-purple);
  border: none;
  border-radius: var(--radius);
  cursor: pointer;
  transition: color 250ms, background-color 250ms;
  // -moz-transition: color 250ms, background-color; /* Firefox 4 */
  // -webkit-transition: color 250ms, background-color; /* Safari and Chrome */
  // -o-transition: color 250ms, background-color; /* Opera */
  // -ms-transition: color 250ms, background-color; /* Explorer 10 */

  .btn-svg {
    fill: var(--clr-white);
  }

  &:hover {
    background-color: var(--clr-purple-light);
    transition: color 250ms, background-color 250ms;
  }
}

.navigation ul {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: var(--space-xxl);

  a:not([class]) {
    display: block;
    color: var(--clr-black);
    text-decoration: none;
    transition: color 250ms, background-color 250ms;
    font-weight: 500;
    font-size: 16px;

    &:hover,
    &:focus {
      color: var(--clr-purple-dark);
    }
  }

  a[aria-current] {
    color: var(--clr-purple-dark);
    font-weight: 700;
  }
}
