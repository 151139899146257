.with-sidebar {
  display: flex;
  flex-wrap: wrap;
  gap: calc(var(--space-xxl) * 2);
}

.with-sidebar > :first-child {
  flex-grow: 1;
}

.with-sidebar > :last-child {
  flex-basis: 0;
  flex-grow: 999;
  min-inline-size: 70%;
}
