@use 'src/scss/abstracts/_breakpoints.scss' as *;

.timeline-container {
  padding-top: 3em;
  text-align: left;

  ul {
    // padding-block: 0;
    // padding-left: 0;
    position: relative;
    li {
      list-style-type: none;
      position: relative;
      width: 3px;
      padding-top: 150px;
      background: #6e59f7;
      @include respond-to('large') {
        //         padding-top: 200px;
        margin: 0 auto;
      }

      div {
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: #6e59f7;
        z-index: 1;

        &::after {
          content: '';
          position: absolute;
          top: 50%;
          width: 70px;
          height: 1px;
          border: 1px dashed #6e59f7;
          @include respond-to('large') {
            width: 100px;
          }
        }
      }

      time {
        font-family: 'Andada Pro';
        position: absolute;
        left: 70px;
        width: 50px;
        font-size: 24px;
        font-weight: 500;
        @include respond-to('small') {
          font-size: 32px;
        }
      }
      p {
        font-family: 'Karla';
        position: absolute;
        top: 40px;
        width: 70vw;
        font-size: var(--step-0);
        font-weight: 400;
        @include respond-to('small') {
          top: 70px;
        }
        @include respond-to('large') {
          width: 350px;
        }
      }

      &:nth-child(odd) {
        @include respond-to('large') {
          text-align: right;
        }

        &::before {
          left: -15px;
          border-width: 8px 16px 8px 0;
          border-color: transparent #f45b69 transparent transparent;
        }

        div::after {
          @include respond-to('large') {
            right: 35px;
          }
        }

        time {
          top: -34px;
          @include respond-to('large') {
            right: -10em;
          }
        }
        p {
          left: 40px;
          @include respond-to('small') {
            left: 70px;
          }
          @include respond-to('large') {
            top: -12px;
            left: -25em;
          }
        }
      }

      &:nth-child(even) {
        &::before {
          right: -15px;
          border-width: 8px 0 8px 16px;
          border-color: transparent transparent transparent #f45b69;
        }
        div::after {
          @include respond-to('large') {
            left: 35px;
          }
        }
        time {
          position: absolute;
          top: -34px;
          @include respond-to('large') {
            left: -6em;
          }
        }
        p {
          left: 40px;
          @include respond-to('small') {
            left: 70px;
          }
          @include respond-to('large') {
            top: -12px;
            left: unset;
            right: -25em;
            position: absolute;
          }
        }
      }
      &:last-child {
        background: transparent;
      }
    }
  }
}
