.center-content {
  --min: 1rem;
  --container-max: 1440px;

  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns:
    minmax(var(--min, 1rem), 1fr)
    minmax(320px, var(--container-max))
    minmax(var(--min, 1rem), 1fr);

  > * {
    grid-column: 2;
  }
}
